































import { Vue, Component } from 'vue-property-decorator'
  @Component({
    name: 'CenterSearchHeader'
  })
export default class extends Vue {
    private searchKeyword = ''

    private create() {
      this.$emit('create')
    }
}
