
































































































import { Component, Vue } from 'vue-property-decorator'
import Container from '@/components/Container/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import CenterSearchHeader from '@/views/center/wssoCenter/components/CenterSearchHeader.vue'
import CogradientProjectDialog from '@/views/center/wssoCenter/components/CogradientProjectDialog.vue'
import WssoDetailsDrawer from '@/views/center/wssoCenter/components/Wsso/WssoDetailsDrawer.vue'
import fetchCenterQuery from '@/api/husuraApi/wssoApi'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'

@Component({
  name: 'WssoCenter',
  components: {
    Container,
    Pagination,
    CenterSearchHeader,
    CogradientProjectDialog,
    WssoDetailsDrawer
  }
})
export default class extends Vue {
  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private centerItemsList = []
  private isCogradientProjectVisible = false
  private showWssoDetailsDrawer = false
  private projectId = ''

  created() {
    this.fetchCenterQuery()
  }

  private fetchCenterQuery(refresh = true) {
    if (refresh) {
      const token = UserModule.oauth.accessToken
      const jwt = UserService.decodeToken(token)
      let userDID
      if (jwt) {
        userDID = jwt.sub
      }
      if (!userDID) {
        this.$message.warning('未获取到DID信息，请重新登录')
        return
      }
      fetchCenterQuery(this.pageSize, (this.page - 1) * this.pageSize, userDID, 'WSSO')
        .then(({ data: res }) => {
          const { data } = res
          this.centerItemsList = data.projects
          this.totalCount = data.projects_aggregate.aggregate.count ?? 0
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private handleLink(link: any) {
    window.open(link.homepage, '_blank')
  }

  private onRowClick(e:any) {
    this.projectId = e.id
    this.showWssoDetailsDrawer = true
  }
}
