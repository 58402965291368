






















import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import WssoApi from '@/api/wsso'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'

@Component({
  name: 'CogradientProjectDialog'
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean

  private projectNo = ''

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private async save() {
    const token = UserModule.oauth.accessToken
    const jwt = UserService.decodeToken(token)
    let userDID
    if (jwt) {
      userDID = jwt.sub
    }
    if (!userDID) {
      this.$message.warning('未获取到DID信息，请重新登录')
      return
    }
    const { data } = await WssoApi.handleProjectSync({
      projectNo: this.projectNo,
      did: userDID
    })
    if (data) {
      this.$message.success('同步成功')
      this.close(true)
    }
  }

  private close(refresh = false) {
    this.projectNo = ''
    this.$emit('close', refresh)
  }
}
