/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query CenterQuery($limit: Int!, $offset: Int!, $userDid: name!, $projectStandard: String!) {
    projects(limit: $limit, offset: $offset, where: {user_did: {_eq: $userDid}, project_standard: {_eq: $projectStandard}}, order_by: {id: desc}) {
      collection_id
      homepage
      id
      project_assets
      project_name
      project_owner_did
      project_standard
      project_type
      project_wsso {
        project_no
      }
    }
    projects_aggregate(where: {user_did: {_eq: $userDid}}) {
      aggregate {
        count
      }
    }
  }
`

function fetchCenterQuery(limit: any, offset: any, userDid: string, projectStandard: string) {
  return fetchGraphQL(operation, 'CenterQuery', { limit: limit, offset: offset, userDid: userDid, projectStandard: projectStandard })
}

export default fetchCenterQuery
