
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query CreateIRecCollectionQuery($id: Int!) {
    projects(where: {id: {_eq: $id}}) {
      collection_id
      project_assets
      project_name
      project_owner_did
      project_irec {
        equipped_capacitor
        expired_at
        production_at
        project_address
        project_country
        project_fuel
        project_id
        project_technology
        register_at
      }
    }
  }
`

function fetchCreateIRecCollectionQuery(id: any) {
  return fetchGraphQL(operation, 'CreateIRecCollectionQuery', { id: id })
}

export default fetchCreateIRecCollectionQuery
