/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'
import fetchWssoDetailsQuery from '@/api/husuraApi/wssoDetailsApi'

const operation = `
  query CreateWssoNFTQuery($id: Int!) {
    project_items(where: {id: {_eq: $id}}) {
      project {
        collection_id
        project_name
        project_owner_did
        project_wsso {
          equipped_capacitor
          id
          power_generation_type
          project_address
          project_coordinate
          project_id
          project_no
          project_owner_name
          project_region
        }
      }
      agent_serial_number
      agent_count
      agent_at
      id
      item_no
    }
  }
`

function fetchCreateWssoNFTQuery(id: string) {
  return fetchGraphQL(operation, 'CreateWssoNFTQuery', { id: id })
}

export default fetchCreateWssoNFTQuery
