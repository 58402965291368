





























































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import Empty from '@/components/Empty/index.vue'
import SyncCarbonDialog from '@/views/center/wssoCenter/components/Wsso/SyncCarbonDialog.vue'
import CreateCollectionDialog from '@/views/center/wssoCenter/components/Wsso/CreateCollectionDialog.vue'
import Pagination from '@/components/Pagination/index.vue'
import CastingNftDialog from '@/views/center/wssoCenter/components/Wsso/CastingNftDialog.vue'
import AssetOwnershipDialog from '@/views/center/wssoCenter/components/Wsso/AssetOwnershipDialog.vue'
import fetchVcsListQuery from '@/api/husuraApi/vcsListApi'
import fetchWssoDetailsQuery from '@/api/husuraApi/wssoDetailsApi'

@Component({
  name: 'WssoDetailsDrawer',
  components: {
    CastingNftDialog,
    Empty,
    LensSpan,
    ListTile,
    Pagination,
    SyncCarbonDialog,
    CreateCollectionDialog,
    AssetOwnershipDialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectId: ''
      }
    }
  }) private initData!: {
    projectId: string
  }

  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private isSyncCarbonDialogVisible = false
  private isCreateCollectionRecDialogVisible = false
  private isCastingNftDialogVisible = false
  private isAssetOwnershipDialogVisible = false
  private wssoList = []
  private projectItemId = ''

  private wssoDetailsList= [
    {
      equipped_capacitor: '',
      id: '',
      project_address: '',
      project_id: '',
      project: {
        collection_id: null,
        homepage: '',
        project_assets: null,
        project_name: '',
        project_owner_did: '',
        project_standard: '',
        project_type: ''
      },
      power_generation_type: '',
      project_coordinate: '',
      project_no: '',
      project_owner_name: '',
      project_region: ''
    }
  ]

  private projectId = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectId = this.initData.projectId
      this.WssoDetailsQuery()
      this.wssoListQuery()
    }
  }

  private async wssoListQuery(refresh = true) {
    if (refresh) {
      fetchVcsListQuery(this.pageSize, (this.page - 1) * this.pageSize, this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.wssoList = data.project_items
          this.totalCount = data.project_items_aggregate.aggregate.count ?? 0
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private WssoDetailsQuery(refresh = true) {
    if (refresh) {
      fetchWssoDetailsQuery(this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.wssoDetailsList = data.project_wsso
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private handleRegister() {
    this.isSyncCarbonDialogVisible = true
  }

  private handleCollection() {
    this.isCreateCollectionRecDialogVisible = true
  }

  private handleCasting(e: any) {
    // if (e.project.collection_id === null) {
    //   this.$message.warning('请先创建Collection')
    //   return
    // }
    this.projectItemId = e.id
    if (!e.right_status) {
      this.isAssetOwnershipDialogVisible = true
    } else {
      this.isCastingNftDialogVisible = true
    }
  }

  private handleLink(link: any) {
    window.open(link, '_blank')
  }
}
