/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query WssoDetailsQuery($project_id: Int!) {
    project_wsso(where: {project_id: {_eq: $project_id}}) {
      equipped_capacitor
      id
      project_address
      project_id
      project {
        collection_id
        homepage
        project_assets
        project_name
        project_owner_did
        project_standard
        project_type
      }
      power_generation_type
      project_coordinate
      project_no
      project_owner_name
      project_region
    }
  }
`

function fetchWssoDetailsQuery(projectId: string) {
  return fetchGraphQL(operation, 'WssoDetailsQuery', { project_id: projectId })
}

export default fetchWssoDetailsQuery
